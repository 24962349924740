.onbording_wrap {
  margin-block: 2rem 3rem;
}

.onbording_wrap label {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.onbording_wrap label > span {
  font-family: 'Factor A';
  font-size: 0.875rem;
}

.onbording button {
  width: 400px;
}
