.pagination-container {
  margin-top: 20px;
  display: flex;
}

.pagination-block {
  width: 100%;
  display: flex;
  flex-flow: 1 1 auto;
  justify-content: center;
  align-items: center;
}

.pagination-button {
  background-color: #8fcb24;
  color: #fff;
  border: none;
  padding: 8px 16px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 4px;
}

.pagination-button:hover {
  background-color: #65990b;
}

.pagination-button:disabled {
  background-color: #d9d9d9;
  color: #ccc;
  cursor: not-allowed;
}

.pagination-button.active {
  background-color: #ffffff;
  color: #8fcb24;
  border: 1px solid #8fcb24;
}

.pagination-select {
  padding: 8px;
  background-color: #ffffff;
  color: #8fcb24;
  border: 1px solid #8fcb24;
  border-radius: 4px;
}
